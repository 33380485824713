(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-ui/helper/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/tb-ui/helper/helpers/handlebars-helpers.js');

var isServerSide = typeof exports === 'object',
  componentHelper;

if (isServerSide) {
  var trinidad = require('trinidad-core').core;
  componentHelper = trinidad.components.require('utils', 'component-helper').api;

  registerHelpers(require('hbs'));
} else {
  componentHelper = svs.utils.component_helper.helper;
  registerHelpers(Handlebars);
}

function registerHelpers(hbs) {
  hbs.registerHelper('tur', function() {
    return componentHelper.apply(this, [arguments, isServerSide ? 'tur' : svs.tur]);
  });

  hbs.registerHelper('numbergames', function() {
    return componentHelper.apply(this, [arguments, isServerSide ? 'numbergames' : svs.numbergames]);
  });

  hbs.registerHelper('tb-ui', function() {
    return componentHelper.apply(this, [arguments, isServerSide ? 'tb_ui' : svs.tb_ui]);
  });

  hbs.registerHelper('accountservices', function() {
    return componentHelper.apply(this, [arguments, isServerSide ? 'accountservices' : svs.accountservices]);
  });
}


 })(window);